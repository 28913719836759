import Vue from "vue";
import PollsService from "@/services/api/polls.service";
import Store from "@/store";

interface PollsStoreI {
  conferencePolls: Array<any>;
  newPoll: any;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: PollsStoreI = {
  conferencePolls: [],
  newPoll: null,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  newPoll() {
    if (state.newPoll) {
      const index = state.conferencePolls.findIndex((x) => x.id == state.newPoll.id);
      if (index >= 0) {
        return state.conferencePolls[index];
      }
    }
    return null;
  },

  filteredConferencePolls() {
    if (state.newPoll) {
      return state.conferencePolls.filter((x) => x.id != state.newPoll.id);
    }
    return state.conferencePolls;
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setConferencePolls(state: PollsStoreI, polls) {
    state.conferencePolls = polls;
  },

  updateConferencePoll(state: PollsStoreI, poll) {
    const index = state.conferencePolls.findIndex((x) => x.id == poll.id);
    if (index >= 0) {
      Vue.set(state.conferencePolls, index, poll);
    }
  },

  setNewPoll(state: PollsStoreI, poll) {
    state.newPoll = poll;
  },

  updateVoted(state: PollsStoreI, { pollId, choiceId, voted }) {
    const index = state.conferencePolls.findIndex((x) => x.id == pollId);
    if (index >= 0) {
      const newChoices = state.conferencePolls[index].choices;
      const allowMultiple = state.conferencePolls[index].allow_multiple_answers;
      newChoices.forEach((choice) => {
        if (choice.id == choiceId) {
          choice.voted = voted;
        } else if (!allowMultiple) {
          choice.voted = false;
        }
      });
      Vue.set(state.conferencePolls, index, { ...state.conferencePolls[index], choices: newChoices });
    }
  },

  updateVotedPollChoice(state: PollsStoreI, { id, total_votes }) {
    state.conferencePolls.forEach((poll) => {
      poll.choices.forEach((choice) => {
        if (choice.id == id) {
          choice.total_votes = total_votes;
        }
      });
    });
  },

  closedPoll(state: PollsStoreI, pollId) {
    const index = state.conferencePolls.findIndex((x) => x.id == pollId);
    if (index >= 0) {
      state.conferencePolls[index].closed = true;
    }
    if (state.newPoll?.id == pollId) {
      state.newPoll = null;
    }
  },

  openedPoll(state: PollsStoreI, pollId) {
    const index = state.conferencePolls.findIndex((x) => x.id == pollId);
    if (index >= 0) {
      state.conferencePolls[index].closed = false;
    }
  },

  publishedPoll(state: PollsStoreI, data) {
    const index = state.conferencePolls.findIndex((x) => x.id == data.id);
    if (index >= 0) {
      Vue.set(state.conferencePolls, index, data);
    } else {
      state.conferencePolls.unshift(data);
      if (!data.closed && !data.choices.some((x) => x.voted)) {
        state.newPoll = data;
      }
    }
  },

  unpublishedPoll(state: PollsStoreI, pollId) {
    state.conferencePolls = state.conferencePolls.filter((x) => x.id != pollId);
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async fetchConferencePolls({ commit }, { conferenceId, filters }) {
    commit("setConferencePolls", []);
    const response = await PollsService.getConferencePolls(conferenceId, filters);
    commit("setConferencePolls", response);
    response.forEach((poll) => {
      Store.dispatch("notifications/tryToReadNotification", {
        notificationType: "NEW_POLL",
        notificationObjectId: poll.id,
      });
    });
    return response;
  },

  async votePoll({ commit }, { conferenceId, poll, choiceId }) {
    const filters = {
      stage: poll.stage,
      session: poll.session,
      showroom: poll.showroom,
    };
    const response = await PollsService.pollVote(conferenceId, filters, poll.id, choiceId);
    Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Vote submited"), "success");

    commit("updateVoted", { pollId: poll.id, choiceId, voted: response.voted });
    return response;
  },

  handlePollChoiceVoted({ commit }, { id, total_votes }) {
    commit("updateVotedPollChoice", { id, total_votes });
  },

  handlePollPublished({ commit }, data) {
    commit("publishedPoll", data);
  },
  handlePollUnpublished({ commit }, data) {
    commit("unpublishedPoll", data);
  },

  handlePollOpened({ commit }, data) {
    commit("openedPoll", data);
  },
  handlePollClosed({ commit }, data) {
    commit("closedPoll", data);
  },
};

// ---------------------------------------------------------------- Module ----------------------------------------------------------------
const polls = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default polls;
